/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useData } from '../DataContext';
import TypeIcon from './TypeIcon';

const PokemonCard = ({ pokemon, onClick }) => {
    const { getTypeById } = useData();
    return (
        <div className="bg-gray-300/80 rounded-lg shadow-lg flex flex-col items-stretch p-2" key={pokemon.id} onClick={onClick}>
            <div className="flex justify-between items-start">
                <span className="font-mono font-semibold">
                    {`#${pokemon.id}`}
                </span>
                <span className="bg-gray-800 text-white font-mono font-bold flex items-center justify-center p-1 rounded-full w-6 h-6 text-sm">
                    {pokemon.generation}
                </span>
            </div>
            <img src={pokemon.image} alt={pokemon.name} className="w-24 h-24 rounded-lg mx-auto" />
            <span className="text-center font-bold text-lg">
                {pokemon.name}
            </span>
            <div className="flex items-center justify-evenly">
                {pokemon.types.map((t) => <TypeIcon type={getTypeById(t)} key={t} />)}
            </div>
        </div>
    );
};

export default PokemonCard;
