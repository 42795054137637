/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import React, { useState } from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import TypeIcon from '../components/TypeIcon';
import { useData } from '../DataContext';
import PokemonImage from '../components/PokemonImage';
import EvolutionTree from './EvolutionTree';

const PokemonDetails = ({ onClose, onChange, pokemon }) => {
    const { generationsLabels, getTypeById, getPokemonById } = useData();
    const { t, i18n } = useTranslation();

    const [showShiny, setShowShiny] = useState(false);

    const handleClick = () => {
        setShowShiny((v) => !v);
    };

    const onMaskClick = (e) => {
        if (e.target.classList.contains('mask')) {
            onClose();
        }
    };
    return (
        <div className={clsx('mask fixed z-30 inset-0 w-screen h-screen bg-transparent flex items-center justify-center p-8 transition duration-300 ease-in-out', pokemon ? 'translate-x-0' : 'translate-x-full')} onClick={onMaskClick}>
            {pokemon && (
                <div className="relative z-40 w-full h-full overflow-y-scroll bg-white text-gray-900 rounded-xl p-4">
                    <div className="absolute top-2 right-2 z-50">
                        <button type="button" onClick={onClose}>
                            <XCircleIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="w-full flex flex-col gap-y-2 items-stretch sm:h-full sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col">
                        <PokemonImage
                            pokemon={pokemon}
                            showShiny={showShiny}
                            onToggleShiny={handleClick}
                            rotateOnToggleShiny
                            showToggleIcon
                            className="w-[50%] sm:w-[65%] rounded-lg mx-auto"
                        />
                        <div className="flex flex-col items-stretch space-y-2 sm:col-span-2 sm:justify-center">
                            <span className="text-center font-mono font-semibold sm:text-2xl">
                                {`#${pokemon?.id}`}
                            </span>
                            <span className="text-center font-bold text-lg sm:text-2xl">
                                {typeof pokemon?.name === 'object' ? pokemon?.name[i18n.language] : pokemon?.name}
                            </span>
                            <div className="flex items-center justify-center space-x-2 sm:text-2xl">
                                <span className="font-semibold">
                                    {t('generation')}
                                </span>
                                <span className="bg-gray-800 text-white font-mono font-bold flex items-center justify-center p-1 rounded-full w-6 h-6 text-sm">
                                    {pokemon?.generation}
                                </span>
                                <span>
                                    {`(${generationsLabels[`${pokemon?.generation ?? 0}`]})`}
                                </span>
                            </div>
                            <div className="flex items-center justify-evenly">
                                {pokemon?.types.map((typeId) => <TypeIcon type={getTypeById(typeId)} key={typeId} alwaysShow large />)}
                            </div>
                            <div className="flex items-center justify-evenly sm:text-xl">
                                <div className="flex items-center space-x-2">
                                    <span className="font-semibold">{t('weight')}</span>
                                    <span>{`${pokemon?.weight} kg`}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <span className="font-semibold">{t('height')}</span>
                                    <span>{`${pokemon?.height} m`}</span>
                                </div>
                            </div>
                            <dl className="sm:col-span-full grid grid-cols-2 gap-1 sm:grid-cols-3 lg:grid-cols-6 sm: w-fit mx-auto sm:py-4">
                                {Object.entries(pokemon?.stats ?? {}).map(([key, value]) => (
                                    <div key={key} className="overflow-hidden rounded-lg bg-white px-2 py-0.5 shadow flex flex-col items-stretch">
                                        <dt className="flex-1 text-sm font-medium text-gray-500">{t(`stats.${key}`)}</dt>
                                        <dd className="text-3xl font-semibold tracking-tight text-gray-900">{value}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                        <div className="flex flex-col items-center sm:justify-center sm:max-h-full sm:overflow-scroll sm:py-4">
                            <div className="w-full text-center font-bold text-lg sm:text-xl my-2">
                                {t('evolution_tree')}
                            </div>
                            <EvolutionTree
                                main={pokemon}
                                from={Object.entries(pokemon?.evolvedFrom ?? []).map(([pokemonId, condition]) => ({ pokemon: getPokemonById(pokemonId), condition }))}
                                to={Object.entries(pokemon?.evolvesTo ?? []).map(([pokemonId, condition]) => ({ pokemon: getPokemonById(pokemonId), condition }))}
                                showShiny={showShiny}
                                onClick={onChange}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PokemonDetails;
