import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PokemonImage from '../components/PokemonImage';

const Entry = ({
    pokemon, condition, language, showShiny, ...otherProps
}) => (
    <span className="flex items-center px-2 py-2 text-sm font-medium" {...otherProps}>
        <PokemonImage
            pokemon={pokemon}
            showShiny={showShiny}
            className="h-16 w-16 sm:w-24 sm:h-24 rounded-full mx-auto flex-shrink-0"
        />
        <p className="flex-grow ml-4">
            <span className={clsx('text-base sm:text-lg text-gray-700', `${condition}`.length > 0 ? 'font-semibold' : 'italic')}>
                {typeof pokemon?.name === 'object' ? pokemon?.name[language] : pokemon?.name}
            </span>
            <br />
            <span className="text-sm sm:text-base font-medium text-gray-500">
                {condition}
            </span>
        </p>
    </span>
);

const EvolutionTree = ({
    main, from, to, showShiny, onClick,
}) => {
    const { i18n } = useTranslation();
    return (
        <div className="flex flex-col items-center max-h-full">
            <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 flex flex-col items-stretch px-4">
                {from.map(({ pokemon, condition }) => (
                    <li key={pokemon.id} className="relative flex">
                        <Entry pokemon={pokemon} condition={condition} showShiny={showShiny} language={i18n.language} onClick={() => { onClick(pokemon); }} />
                    </li>
                ))}
                <li key={main.id} className="relative flex">
                    <Entry pokemon={main} showShiny={showShiny} condition="" language={i18n.language} />
                </li>
                {to.map(({ pokemon, condition }) => (
                    <li key={pokemon.id} className="relative flex">
                        <Entry pokemon={pokemon} condition={condition} showShiny={showShiny} language={i18n.language} onClick={() => { onClick(pokemon); }} />
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default EvolutionTree;
