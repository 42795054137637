import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { AdjustmentsHorizontalIcon, MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/20/solid';
import PokemonCard from '../components/PokemonCard';
import logo from '../logo.png';
import Combobox from '../components/Combobox';
import { useData } from '../DataContext';

const sortOptions = [
    'id', '-id',
    'name', '-name',
    'weight', '-weight',
    'height', '-height',
];

const sortElements = (criteria, a, b) => {
    const order = criteria.charAt(0) === '-' ? -1 : 1;
    const crit = criteria.replace('-', '');

    if (a[crit] === b[crit]) {
        return 0;
    } if (a[crit] > b[crit]) {
        return order * 1;
    }
    return order * -1;
};

const PokemonList = ({ onClick }) => {
    const { types, pokemon, generationsLabels } = useData();
    const { t, i18n } = useTranslation();
    const [sort, setSort] = useState('id');
    const [type, setType] = useState(null);
    const [generation, setGeneration] = useState(1);
    const [search, setSearch] = useState('');
    const [settingsMode, setSettingsMode] = useState('filters');

    const toggleLanguage = () => {
        if (i18n.language === 'fr') {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('fr');
        }
    };

    const toggleSettingsMode = () => {
        setSettingsMode((m) => (m === 'filters' ? 'search' : 'filters'));
    };

    const data = pokemon.filter((p) => {
        if (type !== null && !p.types.includes(type)) return false;
        if (generation !== null && p.generation !== generation) return false;
        if (search.length > 0 && !p.name[i18n.language].toLowerCase().includes(search)) return false;
        return true;
    })
        .map((p) => ({
            ...p,
            name: p.name[i18n.language],
        }))
        .sort((a, b) => sortElements(sort, a, b));
    const generations = Object.entries(generationsLabels).map(([g, label]) => ({ value: parseInt(g, 10), label: `${g} (${label})` }));

    return (
        <div className="relative flex flex-col items-stretch px-1 py-2 space-y-2">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
                type="button"
                onClick={toggleLanguage}
                className={clsx('absolute top-2 right-2 flex items-center justify-center w-8 h-6 fib', `fi-${i18n.language === 'en' ? 'us' : i18n.language}`)}
            />
            <div className="flex justify-center">
                <img src={logo} className="h-16" alt="application logo" />
            </div>
            <div className="flex items-center rounded-l-full rounded-r-full mx-4 py-1 px-4 space-x-4 bg-gray-100/50">
                <button
                    type="button"
                    onClick={toggleSettingsMode}
                    className="flex items-center justify-center sm:hidden"
                >
                    <MagnifyingGlassIcon className={clsx('h-5 w-5', settingsMode === 'filters' ? 'inline' : 'hidden')} aria-hidden="true" />
                    <AdjustmentsHorizontalIcon className={clsx('h-5 w-5', settingsMode === 'search' ? 'inline' : 'hidden')} aria-hidden="true" />
                    <span className="sr-only">Filters</span>
                </button>
                <div className="flex items-center justify-between flex-1 space-x-4">
                    <div className={clsx('flex items-center space-x-4 py-1', settingsMode === 'filters' ? 'block' : 'hidden sm:block')}>
                        <Combobox
                            label={t('sort')}
                            options={sortOptions.map((o) => ({ value: o, label: t(`sort_options.${o}`) }))}
                            selected={sort}
                            onChange={setSort}
                            align="left"
                        />
                    </div>
                    <div className={clsx('flex items-center space-x-4', settingsMode === 'search' ? 'block' : 'hidden sm:block')}>
                        <div className="relative rounded-md shadow-sm">
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={t('search')}
                                className="block w-full rounded-md border-0 py-1 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6 bg-gray-100/50"
                            />
                            <button
                                type="button"
                                onClick={() => { setSearch(''); }}
                                className="absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <XCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span className="sr-only">Clear search</span>
                            </button>
                        </div>
                    </div>
                    <div className={clsx('flex items-center space-x-4 py-1', settingsMode === 'filters' ? 'block' : 'hidden sm:block')}>
                        <Combobox
                            label={t('type')}
                            options={types.map((tt) => ({ value: tt.id, label: tt.name[i18n.language] }))}
                            selected={type}
                            onChange={setType}
                            align="right"
                            addEmptyOption
                            emptyOptionLabel={t('all_m')}
                        />
                        <Combobox
                            label={t('generation')}
                            options={generations}
                            selected={generation}
                            onChange={setGeneration}
                            align="right"
                            addEmptyOption
                            emptyOptionLabel={t('all_f')}
                        />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-2 p-2">
                {data.map((item) => (
                    <PokemonCard pokemon={item} key={item.id} onClick={() => { onClick(item); }} />
                ))}
            </div>
        </div>
    );
};

export default PokemonList;
