import React, { useEffect, useState } from 'react';
import SplashScreen from './pages/SplashScreen';
import { useData } from './DataContext';
import PokemonList from './pages/PokemonList';
// eslint-disable-next-line import/no-absolute-path
import '/node_modules/flag-icons/css/flag-icons.min.css';
import PokemonDetails from './pages/PokemonDetails';

function App() {
    // TODO : Reenable splash screen
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [selectedPokemon, setSelectedPokemon] = useState(null);
    const {
        loading, types, pokemon,
    } = useData();

    useEffect(() => {
        if (loading === false) {
            setShowSplashScreen(false);
        }
    }, [loading]);

    return (
        <div className="min-h-[100vh] h-full w-screen bg-[#ffcb05]">
            <SplashScreen isVisible={showSplashScreen} />
            {!loading && pokemon.length > 0 && types.length > 0 && (
                <>
                    <PokemonList onClick={(p) => { setSelectedPokemon(p); }} />
                    {selectedPokemon !== null && (
                        <PokemonDetails pokemon={selectedPokemon} onClose={() => { setSelectedPokemon(null); }} onChange={(p) => { setSelectedPokemon(p); }} />
                    )}
                </>
            )}
        </div>
    );
}

export default App;
