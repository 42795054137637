/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React from 'react';

const PokemonImage = ({
    pokemon, showShiny, rotateOnToggleShiny = false, onToggleShiny, showToggleIcon = false, className,
}) => (
    <div className={clsx('relative aspect-square group', className)} onClick={onToggleShiny}>
        <div className="w-full h-full relative">
            <img
                src={pokemon?.image}
                alt={pokemon?.name}
                className={clsx(
                    'absolute inset-x-1/2 inset-y-1/2',
                    'transition-all duration-500 ease-linear',
                    showShiny ? 'opacity-0' : 'opacity-100',
                )}
                style={rotateOnToggleShiny ? {
                    transform: `translate(-50%, -50%) ${showShiny ? 'rotate(720deg)' : 'rotate(0deg)'}`,
                } : {
                    transform: 'translate(-50%, -50%)',
                }}
            />
            <img
                src={pokemon?.image_shiny}
                alt={pokemon?.name}
                className={clsx(
                    'absolute inset-x-1/2 inset-y-1/2',
                    'transition-all duration-500 ease-linear',
                    showShiny ? 'opacity-100' : 'opacity-0',
                )}
                style={rotateOnToggleShiny ? {
                    transform: `translate(-50%, -50%) ${showShiny ? 'rotate(720deg)' : 'rotate(0deg)'}`,
                } : {
                    transform: 'translate(-50%, -50%)',
                }}
            />
        </div>
        { showToggleIcon && (
            <ArrowPathIcon className="absolute bottom-2 -right-4 w-4 h-4" />
        )}
    </div>
);

export default PokemonImage;
