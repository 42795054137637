import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { Fragment } from 'react';

const Combobox = ({
    label, options, selected, align = 'left', onChange, addEmptyOption = false, emptyOptionLabel = 'Tous',
}) => (
    <Menu as="div" className="relative inline-block text-left">
        <div>
            <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                {label}
                <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                />
            </Menu.Button>
        </div>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className={clsx(
                'absolute z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none',
                align === 'left' && 'left-0',
                align === 'right' && 'right-0',
            )}
            >
                <div className="py-1">
                    {addEmptyOption && (
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    type="button"
                                    onClick={() => { onChange(null); }}
                                    className={clsx(
                                        'w-full font-medium flex items-center justify-between px-4 py-2 text-sm',
                                        active && 'bg-indigo-600 text-white',
                                        selected === null && 'font-bold text-gray-900',
                                        !active && !(selected === null) && 'text-gray-500',
                                    )}
                                >
                                    <span>{emptyOptionLabel}</span>
                                    { selected === null && (
                                        <span
                                            className={clsx(
                                                'flex items-center',
                                                active ? 'text-white' : 'text-indigo-600',
                                            )}
                                        >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) }
                                </button>
                            )}
                        </Menu.Item>
                    )}
                    {options.map((option) => (
                        <Menu.Item key={option.value}>
                            {({ active }) => (
                                <button
                                    type="button"
                                    onClick={() => { onChange(option.value); }}
                                    className={clsx(
                                        'w-full font-medium flex items-center justify-between px-4 py-2 text-sm',
                                        active && 'bg-indigo-600 text-white',
                                        selected === option.value && 'font-bold text-gray-900',
                                        !active && !(selected === option.value) && 'text-gray-500',
                                    )}
                                >
                                    <span>
                                        {option.label}
                                    </span>
                                    { selected === option.value && (
                                        <span
                                            className={clsx(
                                                'flex items-center',
                                                active ? 'text-white' : 'text-indigo-600',
                                            )}
                                        >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) }

                                </button>
                            )}
                        </Menu.Item>
                    ))}
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
);

export default Combobox;
