import React, { useState, useEffect } from 'react';
import './SplashScreen.css';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import logo from '../logo.png';

const SplashScreen = ({ isVisible }) => {
    const { t } = useTranslation();
    const [shake, setShake] = useState(false);
    const [zoom, setZoom] = useState(false);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
    // Rotate the Pokeball for 2 seconds
        const timeout = setTimeout(() => {
            setShake(true);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (!isVisible) {
            setZoom(true);
            // Zoom in the Pokeball for 1 seconds
            const timeout = setTimeout(() => {
                setVisible(false);
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [isVisible]);

    return (
        <div className={clsx('relative flex flex-col justify-center items-center h-screen w-screen', !visible && 'hidden')}>
            <div className={clsx('pokeball', shake && 'shake', zoom && 'zoom-in')} />
            <div className="absolute inset-x-0 bottom-0 flex flex-col justify-end items-center py-4">
                <img src={logo} className={clsx('max-h-24 transition-opacity ease-in-out duration-300', zoom ? 'opacity-0' : 'opacity-100')} alt="application logo" />
                <span className={clsx('font-mono font-semibold mt-2 animate-pulse transition-opacity ease-in-out duration-300', (shake && visible) ? 'opacity-100' : 'opacity-0')}>
                    {`${t('loading')}...`}
                </span>
            </div>
        </div>
    );
};

export default SplashScreen;
