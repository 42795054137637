import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TypeIcon = ({ type, alwaysShow = false, large = false }) => {
    const { i18n } = useTranslation();
    return (
        <div className="rounded-l-full rounded-r-full flex items-center bg-gray-700 text-white group overflow-hidden border-2 border-[#ffcb05]">
            <div className="rounded-full flex justify-center items-center">
                <img src={type.image} alt={type.name[i18n.language]} className={clsx('h-6 w-6 rounded-full', large && 'sm:h-8 sm:w-8')} />
            </div>
            <span className={clsx(
                'text-xs sm:text-base w-12 sm:w-16 sm:font-semibold text-center opacity-100 transition-all',
                alwaysShow ? '' : 'lg:w-0 lg:opacity-0 group-hover:w-12 group-hover:lg:w-16 group-hover:opacity-100 group-hover:px-1',
            )}
            >
                {type.name[i18n.language]}

            </span>
        </div>
    );
};
export default TypeIcon;
