import React, {
    createContext, useContext, useEffect, useMemo, useState,
} from 'react';

export const DataContext = createContext();

const API_URL = process.env.REACT_APP_API_URL;

const delay = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

const generationsLabels = {
    1: 'Kanto',
    2: 'Johto',
    3: 'Hoenn',
    4: 'Sinnoh',
    5: 'Unova',
    6: 'Kalos',
    7: 'Alola',
    8: 'Galar',
    9: 'Paldea',
};

export const DataProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [pokemon, setPokemon] = useState([]);
    const [types, setTypes] = useState([]);

    const fetchData = async () => {
        try {
        // Use Promise.allSettled to include delays
            const [result1, result2] = await Promise.allSettled([
                fetch(`${API_URL}types`).then((response) => response.json()),
                fetch(`${API_URL}pokemon`).then((response) => response.json()),
                // TODO : Reenable delay
                delay(4000), // Delay for at least 4 seconds
            ]);

            // Extract the data from the settled promises
            const data1 = result1.status === 'fulfilled' ? result1.value.data : [];
            const data2 = result2.status === 'fulfilled' ? result2.value.data : [];

            // Update state with the fetched data
            setTypes(data1);
            setPokemon(data2);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // const minStats = pokemon.reduce((min, item) => Object.fromEntries(
    //     Object.entries(item.stats).map(([k, v]) => [k, Math.min(min[k] ?? 0, v)]),
    // ));

    // const maxStats = pokemon.reduce((max, item) => Object.fromEntries(
    //     Object.entries(item.stats).map(([k, v]) => [k, Math.max(max[k] ?? 0, v)]),
    // ));

    // TODO : do something with stat range ?

    const getPokemonById = (id) => pokemon.find((p) => parseInt(p.id, 10) === parseInt(id, 10));
    const getTypeById = (id) => types.find((t) => parseInt(t.id, 10) === parseInt(id, 10));

    const providedValues = useMemo(() => ({
        loading, types, pokemon, generationsLabels, getPokemonById, getTypeById,
    }), [loading, types, pokemon]);

    return (
        <DataContext.Provider value={providedValues}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useData must be used within an DataProvider');
    }
    return context;
};
